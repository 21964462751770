import { extendWithAuthorization, http } from '@/http'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { reactive, readonly, toRefs } from 'vue'
import useCacheStore from './cache.store'

const db = firebase.firestore()

const state = reactive({
  ldapOverrides: []
})

export default function() {
  const getLdapOverrides = async(force = false) => {
    if (!state.ldapOverrides.length || force) {
      const overrides = await db
        .collection('LDAP_OVERRIDE')
        .get()
        .then(querySnap => querySnap.docs.map(doc => ({ ...doc.data(), email: doc.id })))

      state.ldapOverrides = overrides
    }
    return state.ldapOverrides
  }

  const deleteLdapOverride = async email => {
    state.ldapOverrides = state.ldapOverrides.filter(o => o.email !== email)
    await db.doc(`LDAP_OVERRIDE/${email}`).delete()
    clearCache(email)
  }

  const setLdapOverride = async({ email, ...override }) => {
    if (!email) return
    await db.doc(`LDAP_OVERRIDE/${email}`).set(override)
    clearCache(email)
    return getLdapOverrides(true)
  }
  const clearCache = async email => {
    const cacheStore = useCacheStore()
    const project = cacheStore.caches.value.find(c => c.app === 'referential').project
    
    const extended = await extendWithAuthorization(http)
    await extended.post(`cache/${project}`, { searchParams: { cache: `user:/ldap/user/${email}` } })
  }

  return {
    ...toRefs(readonly(state)),
    getLdapOverrides,
    setLdapOverride,
    deleteLdapOverride
  }
}
