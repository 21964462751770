<template>
  <div class="ldap-override-view">
    <paginated-panel :items="ldapOverrides" :paginated="false">
      <template #header>
        <div class="title">
          Surchage ldap
        </div>
        <div class="pagination-header">
          Tout sera effacé ce soir à minuit.
          <button class="main-button second-accent" @click="openEditor()"> <v-svg h="1.4em" name="add" /> Nouvelle surcharge </button>
        </div>
      </template>
      <template #content="slotProps">
        <div class="override">
          <div class="email">{{ slotProps.item.email }}</div>
          <div class="actions">
            <button class="inline-button main-accent" @click="openEditor(slotProps.item)"><v-svg h="1.4em" name="edit" /> Modifier</button>
            <button class="inline-button destructive" @click="deleteLdapOverride(slotProps.item)">
              <v-svg h="1.4em" name="delete" /> Supprimer
            </button>
          </div>
        </div>
      </template>
    </paginated-panel>
  </div>
  <ldap-override-editor v-if="editorOpened" :override="editionItem" @delete="deleteLdapOverride" @save="setLdapOverride" @close="closeEditor" />
</template>

<script>
  import useModalEditor from '@/mixins/modalEditor'
  import useLdapOverrideStore from '@/store/ldap-override.store'
  import PaginatedPanel from '@/components/PaginatedPanel'
  import LdapOverrideEditor from './LdapOverrideEditor'

  export default {
    name: 'LdapOverride',
    components: {
      PaginatedPanel,
      LdapOverrideEditor
    },
    setup() {
      const modalEditor = useModalEditor()

      const ldapOverrideStore = useLdapOverrideStore()
      ldapOverrideStore.getLdapOverrides()

      const { ldapOverrides } = ldapOverrideStore

      const deleteLdapOverride = ({ email }) => {
        if (confirm(`Supprimer la surcharge pour ${email} ?`)) ldapOverrideStore.deleteLdapOverride(email)
      }
      const setLdapOverride = override => {
        ldapOverrideStore.setLdapOverride(override)
        modalEditor.closeEditor(true)
      }

      return {
        ...modalEditor,
        ldapOverrides,
        setLdapOverride,
        deleteLdapOverride
      }
    }
  }
</script>

<style lang="scss" scoped>
  .ldap-override-view {
    background: var(--color-white);
    box-shadow: var(--shadow-default);
    border-radius: 1rem;
    .title {
      font-size: 1.125rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
    .paginated-panel {
      box-shadow: none;
    }
    .pagination-header {
      display: flex;
      justify-content: space-between;
      color: var(--color-destructive);
      margin-bottom: 16px;
    }
    .override {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      padding: 0;
      border-top: 1px solid hsla(var(--hsla-black), 0.1);
      &:hover {
        background: hsla(var(--hsla-black), 0.1);
      }
      .email {
        font-weight: bold;
      }
      .actions {
        display: flex;
        .inline-button {
          padding-left: 0;
        }
      }
    }
  }
</style>
