import firebase from 'firebase/app'
import 'firebase/firestore'
import { reactive, readonly, toRefs } from 'vue'

const db = firebase.firestore()

const state = reactive({
  caches: [],
})

export default function() {
  const fetchCaches = (force = false) => {
    if (force || !state.caches.length)
      db.collection('CACHES')
        .orderBy('app')
        .get()
        .then(querySnap => {
          state.caches = querySnap.docs.map(doc => doc.data())
        })
  }

  return {
    ...toRefs(readonly(state)),
    fetchCaches
  }
}
