import { ref } from 'vue'

export default function() {
  const editorOpened = ref(false)
  const editionItem = ref(null)

  const openEditor = item => {
    editorOpened.value = true
    editionItem.value = item
  }

  const closeEditor = force => {
    if (force || confirm('Annuler les modifications ?')) {
      editorOpened.value = false
      editionItem.value = null
    }
  }

  return {
    editorOpened,
    editionItem,
    openEditor,
    closeEditor
  }
}
