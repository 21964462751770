<template>
  <div class="copy-user">
    <div class="title">Copier les infos utilisateurs</div>
    <div class="desc">
      Permet de copier les habilitations et de réinitialiser l'authentification
    </div>

    <div class="inputs">
      <input v-model.trim="sourceMail" type="text" class="main-input white" placeholder="Source">
      <v-svg name="arrow_right" />
      <input v-model.trim="targetMail" type="text" class="main-input white" placeholder="Cible">
    </div>
    <div class="params">
      <div> <v-checkbox v-model="deleteData" /> Supprimer les habilitations de l'user source </div>
      <div> <v-checkbox v-model="resetAuth" /> Réinitialiser l'authentification </div>
    </div>
    <div class="validate">
      <button :disabled="!canValidate" class="main-button second-accent" @click="validate"> <v-svg h="1.4em" name="checkmark" /> Valider </button>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue'
  import useAuthorizationStore from '@/store/authorization.store'

  const sourceMail = ref('')
  const targetMail = ref('')
  const deleteData = ref(false)
  const resetAuth = ref(false)

  const canValidate = computed(() => {
    const mailIsValid = mail => /(\w+\.?|-?\w+?)+@\w+\.?-?\w+?(\.\w{2,3})+/g.test(mail)

    return mailIsValid(sourceMail.value) && mailIsValid(targetMail.value)
  })

  const authorizationStore = useAuthorizationStore()

  const validate = () => {
    if (canValidate.value) {
      authorizationStore.copyUserRoles(sourceMail.value, targetMail.value, deleteData.value, resetAuth.value)
      sourceMail.value = ''
      targetMail.value = ''
      deleteData.value = false
      resetAuth.value = false
    }
  }
</script>

<style lang="scss" scoped>
  .copy-user {
    padding: 2rem;
    background: var(--color-white);
    box-shadow: var(--shadow-default);
    border-radius: 1rem;

    .title {
      font-size: 1.125rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
    .inputs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 16px 0;
      input {
        width: 40%;
      }
    }
    .params {
      display: flex;
      gap: 2rem;
      > div {
        display: flex;
        gap: 0.5rem;
        align-items: center;
      }
    }

    .validate {
      margin-top: 0.5rem;
    }
  }
</style>
