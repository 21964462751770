<template>
  <div class="tools-view">
    <ldap-override />
    <copy-user />
    <ldap-infos />
  </div>
</template>

<script>
  import LdapOverride from '@/components/tools/LdapOverride'
  import CopyUser from '@/components/tools/CopyUser'
  import LdapInfos from '@/components/tools/LdapInfos'

  export default {
    name: 'Tools',
    components: {
      LdapOverride,
      CopyUser,
      LdapInfos
    },
  }
</script>

<style lang="scss" scoped>
  .tools-view {
    padding: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
</style>
