<template>
  <div class="ldap-infos">
    <div class="header">
      <div>
        <div class="title">Données du ldap</div>
        <div class="desc">
          Récupérer les informations ldap d'un utilisateur
        </div>
      </div>

      <div class="inputs">
        <search-user @select="displayInfos" />
      </div>
    </div>

    <div class="infos"> <v-svg h="1.4em" name="close" @click="displayInfos()" /> <div v-html="infos" /> </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue'

  const infos = ref('')
  const displayInfos = (user = {}) => {
    infos.value = syntaxHighlight(JSON.stringify(user, null, 2))
  }

  function syntaxHighlight(json) {
    json = json
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function(match) {
      var cls = 'json-number'
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'json-key'
        } else {
          cls = 'json-string'
        }
      } else if (/true|false|null/.test(match)) {
        cls = 'json-boolean'
      }
      return '<span class="' + cls + '">' + match + '</span>'
    })
  }
</script>

<style lang="scss" scoped>
  .ldap-infos {
    padding: 2rem;
    background: var(--color-white);
    box-shadow: var(--shadow-default);
    border-radius: 1rem;

    .header {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 16px;
    }

    .title {
      font-size: 1.125rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    .infos {
      position: relative;
      margin-top: 8px;
      font-family: 'Source Code Pro', monospace;
      font-size: 14px;
      outline: none;
      border: 1px solid hsla(var(--hsla-black), 0.1);
      border-radius: 8px;
      padding: 12px;
      white-space: pre;
      color: #7b7b7e;
      background: #fafafa;
      .v-svg {
        position: absolute;
        top: 8px;
        right: 8px;
      }
    }
  }
</style>

<style>
  .json-string {
    color: #449341;
  }
  .json-number {
    color: #fb5813;
  }
  .json-boolean {
    color: #ba15ba;
  }
  .json-key {
    color: #c04b58;
  }
</style>
