<template>
  <side-popup @close="$emit('close')">
    <div class="head">
      <div />
      <button class="inline-button destructive" @click="$emit('close')"><v-svg name="close" />Fermer</button>
    </div>
    <div class="content">
      <div class="label">Email</div>
      <input v-model.trim="email" :disabled="override" type="text" class="main-input white" placeholder="Email (pas de verif)">
      <div class="label">JSON</div>
      <textarea v-model="jsonString" :class="['textarea', { error: !jsonIsValid }]" cols="30" rows="5" />
      <div />
      <div class="label">Exemples de champs: iirMgtSite, iirJobLabel, ...</div>
    </div>
    <template #footer>
      <div class="footer">
        <div class="delete" @click="deleteLdapOverride"><v-svg name="delete" /></div>
        <div class="save" :disabled="!jsonIsValid" @click="saveLdapOverride"><v-svg name="save" />Enregistrer </div>
      </div>
    </template>
  </side-popup>
</template>

<script>
  import { computed, onMounted, ref, inject } from 'vue'

  export default {
    name: 'LdapOverrideEditor',
    props: {
      override: {
        type: Object,
        default: null
      }
    },
    emits: ['close', 'save', 'delete'],
    setup(props, context) {
      const userInfos = inject('userInfos')
      const email = ref(props.override?.email ?? userInfos.mail ?? '')

      const jsonString = ref('{}')

      onMounted(() => {
        if (props.override) {
          const tempOverride = { ...props.override }
          delete tempOverride.email
          jsonString.value = JSON.stringify(tempOverride, null, 2)
        }
      })

      const jsonIsValid = computed(() => {
        let parsed = {}
        try {
          parsed = JSON.parse(jsonString.value)
        } catch (e) {
          return false
        }
        return !!Object.keys(parsed).length
      })

      const saveLdapOverride = () => {
        if (email.value && jsonIsValid.value) {
          context.emit('save', { ...JSON.parse(jsonString.value), email: email.value })
        }
      }

      const deleteLdapOverride = () => context.emit('delete', email.value)

      return {
        email,
        jsonString,
        jsonIsValid,
        saveLdapOverride,
        deleteLdapOverride
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@500&display=swap');

  .head {
    display: grid;
    grid-template-columns: 1fr auto;
    margin-bottom: 24px;
  }
  .label {
    color: hsla(var(--hsla-black), 0.5);
  }
  .content {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 24px;
    .textarea {
      font-family: 'Source Code Pro', monospace;

      font-size: 14px;
      outline: none;
      border: 1px solid hsla(var(--hsla-black), 0.1);
      border-radius: 8px;
      padding: 12px;
      resize: none;
      &:active,
      &:focus {
        // border: 1px solid var(--color-second-accent);
      }
      &.error {
        border-color: var(--color-destructive);
      }
    }
  }
  .footer {
    display: grid;
    grid-template-columns: 44px 1fr;
    justify-items: center;
    height: 44px;
    align-items: center;
    color: var(--color-white);
    .delete,
    .save {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: background 200ms;
    }
    .delete {
      cursor: pointer;
      background: var(--color-destructive);
      &:hover {
        background: var(--color-destructive-darker);
      }
    }
    .save {
      cursor: pointer;
      background: var(--color-second-accent);
      &:hover {
        background: var(--color-second-accent-darker);
      }
    }
  }
</style>
